import React, { lazy, Suspense } from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import * as COMMONS from "common/common"
import { AnimatePresence } from "framer-motion"
import { ErrorBoundaryComponent, TopProgressBar } from "components"

const MinimalLayout = lazy(() => import("./layouts/Minimal"))
const AdminMainLayout = lazy(() => import("./layouts/AdminMain"))
const ClientMainLayout = lazy(() => import("./layouts/ClientMain"))

const AdminLoginView = lazy(() => import("./views/Admin/Login"))
// const AdminHomeView = lazy(() => import("./views/Admin/Home"))
const AdminHomeV2View = lazy(() => import("./views/Admin/HomeV2"))
const AdminCategoriesView = lazy(() => import("./views/Admin/Categories"))
const AdminOccasionVersionOccasionsView = lazy(() =>
  import("./views/Admin/OccasionVersion/Occasions")
)
const AdminOccasionVersionOccasionsDetailView = lazy(() =>
  import("./views/Admin/OccasionVersion/OccasionsDetail")
)
const AdminCategoryVersionCategoryDetailView = lazy(() =>
  import("./views/Admin/CategoryVersion/CategoryDetail")
)
const AdminMembersView = lazy(() => import("./views/Admin/Members"))
const AdminAudiencesView = lazy(() => import("./views/Admin/Audiences"))
const AdminSettingsView = lazy(() => import("./views/Admin/Settings"))

const ClientLoginView = lazy(() => import("./views/Client/Login"))
const ClientEcLoginView = lazy(() => import("./views/Client/EcLogin"))
const ClientEcConfirmView = lazy(() => import("./views/Client/EcConfirm"))
const ClientRegisterView = lazy(() => import("./views/Client/Register"))
const ClientCampaignView = lazy(() => import("./views/Client/Campaign"))
const ClientProfileUpdateView = lazy(() =>
  import("./views/Client/ProfileUpdate")
)
const ClientMembershipView = lazy(() => import("./views/Client/Membership"))
const ClientCategoriesView = lazy(() => import("./views/Client/Categories"))
const ClientCategoryDetailView = lazy(() =>
  import("./views/Client/CategoryDetail")
)
const ClientCategoryVersionOccurrencesView = lazy(() =>
  import("./views/Client/CategoryVersion/Occurrences")
)
const ClientOccasionVersionOccasionDetailView = lazy(() =>
  import("./views/Client/OccasionVersion/OccasionDetail")
)
const ClientCategoryVersionReservationView = lazy(() =>
  import("./views/Client/CategoryVersion/Reservation")
)
const ClientOccasionVersionReservationView = lazy(() =>
  import("./views/Client/OccasionVersion/Reservation")
)
const ClientRegistrationsView = lazy(() =>
  import("./views/Client/Registrations")
)
const ClientRegistrationDetailView = lazy(() =>
  import("./views/Client/RegistrationDetail")
)

const LineAccessView = lazy(() => import("./views/LineAccess"))
const LineFriendView = lazy(() => import("./views/LineFriend"))
const PermissionErrorView = lazy(() => import("./views/PermissionError"))
const NotFoundView = lazy(() => import("./views/NotFound"))

const Router = () => {
  return (
    <ErrorBoundaryComponent>
      <Suspense fallback={<TopProgressBar />}>
        <AnimatePresence mode="wait">
          <Routes>
            <Route
              path="/"
              element={<Navigate replace to={COMMONS.ADMIN_LOGIN_ROUTE} />}
            />
            <Route
              path={COMMONS.ADMIN_LOGIN_ROUTE}
              element={
                <MinimalLayout>
                  <AdminLoginView />
                </MinimalLayout>
              }
            />
            <Route
              path={COMMONS.ADMIN_HOME_ROUTE}
              element={
                <AdminMainLayout>
                  {/* <AdminHomeView /> */}
                  <AdminHomeV2View />
                </AdminMainLayout>
              }
            />
            <Route
              path={COMMONS.ADMIN_CATEGORIES_ROUTE}
              element={
                <AdminMainLayout>
                  <AdminCategoriesView />
                </AdminMainLayout>
              }
            />
            <Route
              path={`${COMMONS.ADMIN_CATEGORIES_ROUTE}/:categoryId`}
              element={
                <AdminMainLayout>
                  {COMMONS.IS_CATEGORY_VERSION ? (
                    <AdminCategoryVersionCategoryDetailView />
                  ) : (
                    <AdminOccasionVersionOccasionsView />
                  )}
                </AdminMainLayout>
              }
            />
            <Route
              path={`${COMMONS.ADMIN_CATEGORIES_ROUTE}/:categoryId/:occasionId`}
              element={
                <AdminMainLayout>
                  {COMMONS.IS_CATEGORY_VERSION ? (
                    <NotFoundView />
                  ) : (
                    <AdminOccasionVersionOccasionsDetailView />
                  )}
                </AdminMainLayout>
              }
            />
            <Route
              path={COMMONS.ADMIN_MEMBERS_ROUTE}
              element={
                <AdminMainLayout>
                  <AdminMembersView />
                </AdminMainLayout>
              }
            />
            <Route
              path={COMMONS.ADMIN_CAMPAIGN_MEMBERS_ROUTE}
              element={
                <AdminMainLayout>
                  <AdminMembersView />
                </AdminMainLayout>
              }
            />
            <Route
              path={COMMONS.ADMIN_AUDIENCES_ROUTE}
              element={
                <AdminMainLayout>
                  <AdminAudiencesView />
                </AdminMainLayout>
              }
            />
            <Route
              path={COMMONS.ADMIN_SETTINGS_ROUTE}
              element={
                <AdminMainLayout>
                  <AdminSettingsView />
                </AdminMainLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_LOGIN_ROUTE}/:liffId`}
              element={
                <MinimalLayout>
                  <ClientLoginView />
                </MinimalLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_EC_LOGIN_ROUTE}/:liffId`}
              element={
                <MinimalLayout>
                  <ClientEcLoginView />
                </MinimalLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_EC_CONFIRM_ROUTE}/:liffId`}
              element={
                <MinimalLayout>
                  <ClientEcConfirmView />
                </MinimalLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_REGISTER_ROUTE}/:liffId`}
              element={
                <MinimalLayout>
                  <ClientRegisterView />
                </MinimalLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_CAMPAIGN_ROUTE}/:liffId`}
              element={
                <MinimalLayout>
                  <ClientCampaignView />
                </MinimalLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_PROFILE_UPDATE_ROUTE}/:liffId`}
              element={
                <ClientMainLayout>
                  <ClientProfileUpdateView />
                </ClientMainLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_MEMBERSHIP_ROUTE}/:liffId`}
              element={
                <ClientMainLayout>
                  <ClientMembershipView />
                </ClientMainLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_CATEGORIES_ROUTE}/:liffId`}
              element={
                <ClientMainLayout>
                  <ClientCategoriesView />
                </ClientMainLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_CATEGORIES_ROUTE}/:liffId/:categoryId`}
              element={
                <ClientMainLayout>
                  <ClientCategoryDetailView />
                </ClientMainLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_CATEGORIES_ROUTE}/:liffId/:categoryId/:occasionId`}
              element={
                <ClientMainLayout>
                  {COMMONS.IS_CATEGORY_VERSION ? (
                    <ClientCategoryVersionOccurrencesView />
                  ) : (
                    <ClientOccasionVersionOccasionDetailView />
                  )}
                </ClientMainLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_CATEGORIES_ROUTE}/:liffId/:categoryId/:occasionId/:occurrenceId`}
              element={
                <ClientMainLayout>
                  {COMMONS.IS_CATEGORY_VERSION ? (
                    <ClientCategoryVersionReservationView />
                  ) : (
                    <ClientOccasionVersionReservationView />
                  )}
                </ClientMainLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_REGISTRATIONS_ROUTE}/:liffId`}
              element={
                <ClientMainLayout>
                  <ClientRegistrationsView />
                </ClientMainLayout>
              }
            />
            <Route
              path={`${COMMONS.CLIENT_REGISTRATION_DETAIL_ROUTE}/:liffId/:registrationId`}
              element={
                <ClientMainLayout>
                  <ClientRegistrationDetailView />
                </ClientMainLayout>
              }
            />
            <Route
              path={COMMONS.LINE_ACCESS_ROUTE}
              element={
                <MinimalLayout>
                  <LineAccessView />
                </MinimalLayout>
              }
            />
            <Route
              path={COMMONS.LINE_FRIEND_ROUTE}
              element={
                <MinimalLayout>
                  <LineFriendView />
                </MinimalLayout>
              }
            />
            <Route
              path={COMMONS.PERMISSION_ERROR_ROUTE}
              element={
                <MinimalLayout>
                  <PermissionErrorView />
                </MinimalLayout>
              }
            />
            <Route
              path={COMMONS.NOT_FOUND_ROUTE}
              element={
                <MinimalLayout>
                  <NotFoundView />
                </MinimalLayout>
              }
            />
            <Route
              path="*"
              element={<Navigate replace to={COMMONS.NOT_FOUND_ROUTE} />}
            />
          </Routes>
        </AnimatePresence>
      </Suspense>
    </ErrorBoundaryComponent>
  )
}

export default Router
