import * as API from "common/api"
import * as COMMONS from "common/common"
import { RightOutlined } from "@ant-design/icons"
import { Card, Descriptions, Image } from "antd"
import styled from "styled-components"

const CustomDescriptions = styled(Descriptions)`
  .ant-descriptions-view {
    border: none;
    border-top: 1px solid ${(props) => props.$publicSettings?.PRIMARY_COLOR};
    border-radius: 0;
  }
  .ant-descriptions-row {
    border-color: ${(props) => props.$publicSettings?.PRIMARY_COLOR};
  }

  .ant-descriptions-item-label {
    display: table-cell;
    text-align: center;
    font-weight: bold;
    background-color: ${(props) => props?.$publicSettings?.PRIMARY_LIGHT_COLOR};
    color: ${(props) => props?.$publicSettings?.PRIMARY_COLOR};
    border-radius: 0.25rem;
  }

  .ant-descriptions-item-content {
    background-color: ${COMMONS.WHITE_COLOR};
    border-radius: 0.25rem;
    text-align: center;
  }
`

const Occasion = (props) => {
  const { publicSettings, occasion } = props

  return (
    <Card
      hoverable
      bordered={false}
      bodyStyle={{
        padding: 0,
      }}
      className="border rounded"
      style={{
        borderColor: publicSettings?.PRIMARY_COLOR,
      }}
    >
      <div className="flex flex-col items-center">
        {occasion?.occasionImages && occasion?.occasionImages?.length > 0 ? (
          occasion.occasionImages[0]?.picUrl ? (
            <Image
              preview={false}
              src={`${API.OCCASIONS_UPLOADS_URL}${occasion.occasionImages[0]?.picUrl}`}
              fallback="/no-image.png"
              height={300}
              width="100%"
              className="object-contain"
              alt={`${COMMONS.DEFAULT_SYSTEM_TYPE}タイプ画像`}
            />
          ) : (
            <Image
              preview={false}
              src="/no-image.png"
              height={300}
              width="100%"
              className="object-contain"
              alt={`${COMMONS.DEFAULT_SYSTEM_TYPE}タイプ画像`}
            />
          )
        ) : (
          <Image
            preview={false}
            src="/no-image.png"
            height={300}
            width="100%"
            className="object-contain"
            alt={`${COMMONS.DEFAULT_SYSTEM_TYPE}タイプ画像`}
          />
        )}
        <div className="flex flex-col w-full">
          <p className="text-center font-bold h-12 leading-6 line-clamp-2 px-4 mb-4">{occasion?.title || ""}</p>
          {occasion?.occasionDetails &&
          occasion?.occasionDetails?.length > 0 ? (
            <CustomDescriptions
              bordered
              column={1}
              size="small"
              layout="vertical"
              $publicSettings={publicSettings}
            >
              {occasion.occasionDetails.map((detail) => (
                <Descriptions.Item
                  key={detail?.label}
                  label={detail?.label || ""}
                >
                  <p className="whitespace-pre-wrap font-bold text-xs">
                    {detail?.value || ""}
                  </p>
                </Descriptions.Item>
              ))}
            </CustomDescriptions>
          ) : (
            ""
          )}
        </div>
      </div>
      {!COMMONS.IS_CATEGORY_VERSION && (
        <div
          className="flex justify-around items-center w-full p-4"
          style={{ backgroundColor: publicSettings?.PRIMARY_COLOR }}
        >
          <div className="flex-1">
            <p className="text-center text-lg font-bold text-white">予約する</p>
          </div>
          <div className="flex-none">
            <RightOutlined className="text-white" />
          </div>
        </div>
      )}
    </Card>
  )
}

export default Occasion
